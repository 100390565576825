<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <v-avatar
            size="60"
            class="mb-4"
            rounded
          >
            <img
              src="/assets/logo/ktgLogo.png"
              alt=""
            >
          </v-avatar>
          <h5>
            Reset Your Password
          </h5>

          <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            label="Password"
            @click:append="show = !show"
          />
          <v-text-field
            v-model="passwordConfirmation"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            label="Confirm Password"
            @click:append="show = !show"
          />

          <v-btn
            class="mb-4"
            block
            color="primary"
            dark
            :disabled="disableReset"
            @click="resetPasswordClick"
          >
            Reset Password
          </v-btn>
          <div class="danger--text">{{ getErrorMessage }}</div>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'Reset',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Reset',
    },
    mounted(){
      this.getToken();
    },
    data () {
      return {
        show: false,
        email: null,
        displayName: null,
        password: null,
        passwordConfirmation: null,
        token: null
      }
    },
    methods: {
      ...mapActions(['resetPassword', 'changePassword']),
      async resetPasswordClick(){
        let resp = await this.resetPassword({"token": this.token, "password": this.password});
        // if(resp){
        //   this.$router.push("/app/home");
        // }
      },
      getToken(){
        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(new URL(currentUrl).search);
        const token = urlParams.get('token');
        const decodedToken = decodeURIComponent(token);

        this.token = decodedToken;
      }
    },
    computed: {
      ...mapGetters(["getErrorMessage"]),
      disableReset(){
        // if(this.password == null){
        //   return true;
        // } else if(this.password != this.passwordConfirmation){
        //   return true;
        // } else if(this.p)
        return this.password == null || this.password != this.passwordConfirmation || this.password.length < 5
      }
    }
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
